const ERRORS = {
  0: 'unknown',
  1: 'USERNAME_SIZE_NOT_VALID',
  2: 'role size not valid',
  3: 'email size not valid',
  4: 'must not be null',
  5: 'Could not find user',
  6: 'JWT token not provided',
  7: 'unauthorised',
  8: "user email mustn't be null",
  9: 'user password must be null',
  10: "user role mustn't be null",
  11: 'NEWS_DESCRIPTION_SIZE_NOT_VALID',
  12: 'NEWS_DESCRIPTION_HAS_TO_BE_PRESENT',
  13: 'news title size not valid',
  14: 'title has to be present',
  15: "Required Integer parameter 'page' is not present",
  16: "Required Integer parameter 'perPage' is not present",
  17: 'user email must be a well-formed email address',
  18: 'news page must be greater or equal 1',
  19: 'perPage must be less or equal 100',
  20: "Required String parameter 'code' is not present",
  21: 'Exception handler not provided',
  22: 'Current request is not a multipart request',
  23: 'Maximum upload size exceeded',
  24: "user avatar mustn't be null",
  25: 'password not valid',
  26: "user password mustn't be null",
  27: 'news not found',
  29: 'ID_MUST_BE_POSITIVE',
  30: 'User already exists',
  31: 'TODO_TEXT_NOT_NULL',
  32: 'Maximum upload size exceeded',
  33: "user avatar mustn't be null",
  34: 'password not valid',
  35: "user password mustn't be null",
  36: 'news not found',
  37: 'TASKS_PAGE_GREATER_OR_EQUAL_1',
  38: 'TASKS_PER_PAGE_GREATER_OR_EQUAL_1',
  39: 'TASKS_PER_PAGE_LESS_OR_EQUAL_100',
  40: 'REQUIRED_INT_PARAM_PAGE_IS_NOT_PRESENT',
  41: 'REQUIRED_INT_PARAM_PER_PAGE_IS_NOT_PRESENT',
  43: 'USER_NAME_HAS_TO_BE_PRESENT',
  44: 'TAGS_NOT_VALID',
  45: 'NEWS_IMAGE_HAS_TO_BE_PRESENT',
  46: 'USER_WITH_THIS_EMAIL_ALREADY_EXIST',
  47: 'Http request not valid',
};

export default ERRORS;
